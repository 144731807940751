import React from 'react';

import axios from 'axios';
import { Link } from 'react-router-dom';

class Year extends React.Component {

    state = { 
        projectsShow: false,
        projects: []
    }

    componentDidMount() {
        this.projectsContainerRef = React.createRef();
        this.yearButtonRef        = React.createRef();

        axios.get('https://leevitoija.com/carnival/api/Project/get/year/' + this.props.year)
            .then(res => {
                this.setState({ projects: res.data });
            })
    }

    adjustProjectsContainerTop() {
        this.projectsContainerRef.current.style.top = 'calc(' + this.yearButtonRef.current.offsetTop + 'px + 1.9em)';
    }


    setActiveBackgroundColor(e, color, unset = false) {
        if(unset) {
            e.target.style.background = 'transparent';
            e.target.style.color = color;
        }

        else {
            e.target.style.background = color;

            var rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
            rgb = rgb ? {
              r: parseInt(rgb[1], 16),
              g: parseInt(rgb[2], 16),
              b: parseInt(rgb[3], 16)
            } : null;

            let luma = 0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b; // per ITU-R BT.709

            if (luma < 170) {
                e.target.style.color = '#fff';
            }

            else {
                e.target.style.color = '#000';
            }
        }
    }

    render() {
        return (
            <li className="lt-year-container">
                <button 
                    className={ "lt-year" + (this.state.projectsShow ? ' hovered' : '')}
                    ref={ this.yearButtonRef }
                    onMouseOver={ () => { this.setState({ projectsShow: true }); this.adjustProjectsContainerTop() } }
                    onMouseLeave={ () => this.setState({ projectsShow: false }) }
                >
                    { this.props.year }
                </button>
                <ul 
                    className="lt-projects"
                    ref={ this.projectsContainerRef }
                    onMouseOver={ () => this.setState({ projectsShow: true }) }
                    onMouseLeave={ () => this.setState({ projectsShow: false }) }
                >
                    { this.state.projects.map((project, key) => {
                        return (
                            <li key={ key }>
                                <Link 
                                    to={ '/' + encodeURIComponent(project.title) } 
                                    style={{ color: project.color }} 
                                    key={ key }
                                    onMouseOver={ e => this.setActiveBackgroundColor(e, project.color) }
                                    onMouseLeave={ e => this.setActiveBackgroundColor(e, project.color, true) }
                                >
                                    { project.title }
                                </Link>
                            </li>
                        );
                    }) }
                </ul>
            </li>
        );
    }

}

export default Year;