import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Year from '../common/Year';

import '../../css/partials/nav.css';

class Nav extends React.Component {
    state = {
        years: [],
        projects: []
    }

    componentDidMount() {
        axios.get('https://leevitoija.com/carnival/api/Project/get/years')
            .then(res => {
                let years = [];

                res.data.map(year => {
                    if(year.length > 0) {
                        years.push(year);
                    }
                });
                this.setState({ years: years });
            });

        axios.get('https://leevitoija.com/carnival/api/Project/get/nav')
            .then(res => {
                this.setState({ projects: res.data });
            });
    }

    render() {
        return (
            <nav className="lt-nav">
                <div className="inner">
                    <div className="upper">
                        <div className="lt-nav-links">
                            <Link to="/about-me">About me</Link>
                            <a href="https://leevitoija.com/app/carnival/var/storage/cv.pdf" target="_blank">CV</a>
                            <a href="https://leevitoija.com/app/carnival/var/storage/exhibitions.pdf" target="_blank">Exhibitions</a>
                        </div>
                    </div>

                    <ul className="lt-nav-projects">
                        {
                            this.state.projects.map((project, key) => {
                                return (
                                    <li className="nav-project" key={ key }>
                                        <Link to={ '/' + encodeURIComponent(project.title) } style={{ color: project.color }} key={ key }>{ project.title }</Link>
                                    </li>
                                );
                            })
                        }
                        { this.state.years.map((year, key) => {
                            return (
                                <React.Fragment key={ key }>
                                    <Year year={year} key={ key } />
                                </React.Fragment>
                            );
                        }) }
                    </ul>
                </div>
            </nav>
        );
    }

}

export default Nav;