import React from 'react';
import ReactDOM from 'react-dom';

class GalleryZoomIn extends React.Component {

    constructor(props) {
        super(props);

        this.containerRef = React.createRef();
    }

    switchImage(e) {
        switch(e.code) {
            case 'ArrowRight':
                this.props.next();
                break;
            case 'ArrowLeft':
                this.props.prev();
                break;
            case 'Escape':
                this.props.close();
                break;                
            default:
                break;
        }
    }

    componentDidUpdate() {
        this.containerRef.current.focus();
    }

    render() {
        return (
            <div 
                className="lt-gallery-zoom-in-container" 
                style={{ display: this.props.toggled ? 'block' : 'none' }}
                onKeyDown={ e => this.switchImage(e) }
                tabIndex="0"
                ref={ this.containerRef }
            >
                <button className="lt-close" onClick={ () => this.props.close() }>Close</button>

                <div className="middle">
                    <button 
                        className="prev" 
                        onClick={ () => this.props.prev() } 
                        dangerouslySetInnerHTML={{ __html: '<' }}
                        style={{ display: this.props.selected == 0 ? 'none' : 'block' }}
                    ></button>
                    <img src={ this.props.media[this.props.selected] !== undefined ? this.props.media[this.props.selected].src : '' } alt=""/>
                    <button 
                        className="next" 
                        onClick={ () => this.props.next() } 
                        dangerouslySetInnerHTML={{ __html: '>' }}
                        style={{ display: this.props.selected == (this.props.media.length - 1) ? 'none' : 'block' }}
                    ></button>
                </div>
            </div>
        );
    }

}

export default GalleryZoomIn;