import React from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';

class Image extends React.Component {

    render() {
        return (
            <div>
                <LazyLoadImage 
                    alt=""
                    src={ this.props.src }
                    effect="blur"
                    style={{width: "100%", display: "block"}}
                    data-fullscreen-id={ this.props.fullscreenId }
                    className="lt-image"
                    onClick={ () => this.props.onClick() }
                />
                <b>{ this.props.description }</b>
            </div>
        );
    }

}

export default Image;