import React from 'react';
import { useParams, withRouter } from 'react-router-dom';

import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

import axios from 'axios';

import Image from './Image';
import GalleryZoomIn from './GalleryZoomIn';

import '../../css/common/gallery.css';

class Gallery extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        media: [],
        loading: false,
        breakpoints: {},
        columnsCount: null,
        description: null,
        selected: 0,
        zoomInToggled: false
    }

    loadImages(props) {
        this.setState({ loading: true });

        axios.get('https://leevitoija.com/carnival/api/Project/get/gallery/' + props.match.params.title)
            .then(res => {
                var doc = new DOMParser().parseFromString(res.data.images, 'text/html');

                this.setState({
                    media: JSON.parse(doc.documentElement.textContent),
                    loading: false
                });

                this.setState({ description: res.data.description });
                this.setState({ color: res.data.color });
                this.setState({ breakpoints: this.state.media.length >= 5 ? {350: 1, 750: 2, 900: 4, 1400: 6} : {350: 1} });
                this.setState({ columnsCount: this.state.media.length >= 5 ? 5 : this.state.media.length });
            })
    }

    componentDidMount() {
        this.loadImages(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.loadImages(nextProps);
    }

    toggleZoomIn(key = null) {
        this.setState({ 
            selected: key,
            zoomInToggled: true
        });
    }

    render() {
        return (
            <div className="lt-gallery ui container-fluid" style={{ marginLeft: '15px', marginRight: '15px' }}>
                { this.state.loading 
                ?
                    <p>Loading...</p>
                :
                    <React.Fragment>
                        <p className="description" style={{ color: this.state.color }} dangerouslySetInnerHTML={{ __html: this.state.description }}></p>
                        <ResponsiveMasonry gutter={ 0 } columnsCountBreakPoints={ this.state.breakpoints }>
                            <Masonry columnsCount={ this.state.columnsCount } gutter="10px">

                                { this.state.media.map((item, key) => {
                                    if(item.type == 'img') {
                                        return (
                                            <Image
                                                src={ item.src }
                                                description={ item.description }
                                                style={{width: "100%", display: "block"}}
                                                onClick={ () => this.toggleZoomIn(key) }
                                                key={ key }
                                            />
                                        );
                                    }

                                    else {
                                        return (
                                            <div key={ key }>
                                                <div className="video" dangerouslySetInnerHTML={{ __html: item.src }}></div>
                                                <b>{ item.description }</b>
                                            </div>
                                        )
                                    }
                                }) }
                            </Masonry>
                        </ResponsiveMasonry>

                        <GalleryZoomIn 
                            media={ this.state.media }
                            selected={ this.state.selected } 
                            toggled={ this.state.zoomInToggled } 
                            close={ () => this.setState({ zoomInToggled: false }) }
                            next={ () => { this.setState({ selected: (this.state.selected + 1) < this.state.media.length ? this.state.selected + 1 : this.state.selected }) } }
                            prev={ () => { this.setState({ selected: (this.state.selected - 1) >= 0 ? this.state.selected - 1 : this.state.selected }) } }
                        />
                    </React.Fragment>
                }
            </div>
        );
    }

}

export default withRouter(Gallery);