import React from 'react';

import axios from 'axios';

class Home extends React.Component {

    state = {description: null};

    componentDidMount() {
        axios.get('https://leevitoija.com/carnival/api/about-me')
            .then(res => {
                this.setState({ description: res.data });
            });
    }

    render() {
        return (
            <div className="ui container home">
                <p className="text" dangerouslySetInnerHTML={{ __html: this.state.description }}>
                </p>
            </div>
        );
    }

}

export default Home;