import React from 'react';
import {
    HashRouter as Router,
    Switch,
    Route,
    Redirect
  } from "react-router-dom";

import axios from 'axios';

// Components
import Nav from './partials/Nav';
import Gallery from './common/Gallery';
import Home from './common/Home';

// Styles
import '../css/vendor/semantic/semantic.min.css'
import '../css/root.css';

class App extends React.Component {
    state = {
        projects: []
    }

    componentDidMount() {
        axios.get('https://leevitoija.com/carnival/api/Project/get')
            .then(res => {
                this.setState({ projects: res.data });
            });
    }

    render() {
        return (
            <div className="lt-grid">
                <Router>
                    <Nav />
                    <Switch>
                        <Route path="/:title" children={ <Gallery /> } />
                        <Route exact path="/" children={ <Redirect to="about-me" /> } />
                    </Switch>
                </Router>
            </div>
        );
    }

}

export default App;